import { Component } from 'react';
import '../styles/accountSelect.css';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';
import Sheet from 'react-modal-sheet';
import { isDiscoveredAccountLinked } from '../utils/helper.utils';

export default class AccountSelect extends Component {
	constructor(props) {
		super(props);

		const queryParams = new URLSearchParams(window.location.search);
		this.isInitialJourney =
			queryParams.get('isInitial') === 'true' ? true : false;
		this.state = {
			isProceed: true,
			time: 60,
			discoveredAccounts: [],
			selectedAccounts: [],
			linkedAccounts: [],
			linkedAccountsFull: [],
			isResend: true,
			OTP: null,
			linkingRefNO: '',
			isLoding: true,
			selectedBank: null,
			isSkip: false,
			isOpenSheet: false,
			isDisable: false,
			isVerifySpinner: false,
		};
		this.authApis = new AuthApis();
	}
	componentDidMount() {
		console.log('global.populerFips - ', global.populerFips);
		let index = global.populerFips
			.map((el) => {
				return el.fipId;
			})
			.indexOf(this.props.match.params.fid);
		this.setState({ selectedBank: global.populerFips[index] });
		setTimeout(() => {
			this.getLinkedAcc();
		}, 500);
		console.log(
			'this.props.match.params.number - ',
			this.props.match.params.number
		);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	initiate = () => {
		this.setState((prevState, prevProps) => ({
			time: prevState.time - 1,
		}));
		if (this.state.time === 0) {
			clearInterval(this.id);
			this.setState({ time: 60, isResend: true });
		}
	};
	getLinkedAcc = () => {
		this.props.updateLoader(true);
		this.authApis.getLinkedAccounts().then((res) => {
			console.log('linked account response    - ', res);
			if (!res) {
				toast.error('Something went wrong,Please try again');
				this.props.updateLoader(false);
			}
			if (res?.status === 'SUCCESS') {
				this.setState({
					linkedAccounts: res.LinkedAccounts.map((el) => {
						return el.accRefNumber;
					}),
				});
				this.setState({
					linkedAccountsFull: res.LinkedAccounts,
				});
				if (this.props.match.params.fid) {
					this.discoverAccount();
				} else {
					if (!global.isAddAccount) {
						if (global?.institutionType === 'LSP') {
							this.props.updateLoader(false);
							this.props.history.push({
								pathname: `/select-lender`,
							});
						} else {
							this.props.updateLoader(false);
							this.props.history.push({
								pathname: `/payment-consent`,
								state: { data: res.LinkedAccounts },
							});
						}
					} else {
						this.discoverAccount();
					}
				}
			} else {
				console.log(res.message);
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				}
				if (this.props.match.params.fid) {
					this.discoverAccount();
				} else {
					this.props.updateLoader(false);
					if (global?.institutionType === 'LSP') {
						this.props.history.push('/select-lender');
					} else {
						this.props.history.push('/payment-consent');
					}
				}
			}
		});
	};
	discoverAccount = () => {
		window.finvuClient
			.discoverAccounts(this.props.match.params.fid, [
				{
					category: 'STRONG',
					type: 'MOBILE',
					value: this.props.match.params.number,
				},
			])
			.then((res) => {
				this.props.updateLoader(false);
				if (!res) {
					toast.error('Something went wrong,Please try again');
				}
				if (res?.status === 'SUCCESS') {
					let disIds = res.DiscoveredAccounts.map((el) => {
						return el.accRefNumber;
					});
					let notLinkedList = [];
					console.log('res.DiscoveredAccounts - ', res.DiscoveredAccounts);
					res.DiscoveredAccounts.forEach((el) => {
						if (!isDiscoveredAccountLinked(el, this.state.linkedAccountsFull)) {
							notLinkedList.push(el);
						}
					});
					this.setState({
						discoveredAccounts: res.DiscoveredAccounts,
						isLoding: false,
						selectedAccounts: notLinkedList,
					});
					console.log('787687 - ', disIds);
					if (notLinkedList.length === 0) {
						this.setState({ isSkip: true });
					}
				} else {
					toast.error(res);
					console.log(res);
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					}
					this.props.history.push(
						`/NoAccountFound/${this.props.match.params.number}?fip=${this.props.match.params.fid}&isInitial=${this.isInitialJourney}`
					);
				}
			});
	};
	accountLinking = () => {
		// this.props.updateLoader(true)
		this.setState({ isDisable: true, isResend: false });
		this.authApis
			.accountLinking(this.props.match.params.fid, this.state.selectedAccounts)
			.then((res) => {
				console.log('res', res);
				// this.props.updateLoader(false)
				this.setState({ isDisable: false });
				if (!res) {
					toast.error('Something went wrong,Please try again');
				}
				if (res.status === 'SUCCESS') {
					this.setState({
						isProceed: false,
						linkingRefNO: res.RefNumber,
						isOpenSheet: true,
					});
					this.setResend();
				} else {
					this.setState({ isResend: true });
					toast.error(res.message);
					console.log(res.message);
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					}
					// this.props.history.push(`/error`)
				}
			});
	};
	directProceed = () => {
		if (global?.institutionType === 'LSP') {
			this.props.history.push(`/select-lender`);
		} else {
			this.props.history.push(`/payment-consent`);
		}
	};
	accountConfirmLinking = () => {
		this.setState({ isDisable: true, isVerifySpinner: true, isResend: false });
		this.authApis
			.accountConfirmLinking(this.state.linkingRefNO, this.state.OTP)
			.then((res) => {
				console.log('res', res);

				if (res.status === 'SUCCESS') {
					this.setState({
						isDisable: false,
						isVerifySpinner: false,
						isResend: true,
					});
					if (global?.institutionType === 'LSP') {
						this.props.history.push(`/select-lender`);
					} else {
						this.props.history.push(`/payment-consent`);
					}
				} else {
					this.setState({
						isVerifySpinner: false,
						OTP: '',
						time: 60,
						isResend: true,
					});
					clearInterval(this.id);
					console.log(res);
					toast.error(res);
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					}
				}
			});
	};
	setResend = () => {
		this.setState({ isResend: false, OTP: '' });
		this.id = setInterval(this.initiate, 1000);
	};
	selectUnselectAcc = (el) => {
		let data = this.state.selectedAccounts;
		if (this.state.selectedAccounts.includes(el)) {
			let index = data.indexOf(el);
			data.splice(index, 1);
		} else {
			data.push(el);
		}
		this.setState({ selectedAccounts: data });
	};
	checker = (arr, target) =>
		arr.every((element) => {
			return target.includes(element);
		});

	handleOTPChange = (e) => {
		const regex = /^$|^[A-Z0-9]+$/;
		const inputValue = e.target.value.toUpperCase();
		if (regex.test(inputValue)) {
			this.setState({ OTP: inputValue });
		}
	};

	render() {
		return (
			<div className="account-select-page mt-9">
				{this.state.isLoding ? null : (
					<div className="container">
						<div className="row px-4 ">
							<div className="col-12 mt-5">
								<h1
									className="fs-20 fw-bold text-start"
									style={{
										color: this.props.customDetails?.headingColor,
										fontFamily: this.props.customDetails?.fontFamily,
									}}
								>
									LINK ACCOUNT
								</h1>
								<p
									className="fs-14 fw-500  text-start"
									style={{
										color: this.props.customDetails?.textColor,
										fontFamily: this.props.customDetails?.fontFamily,
									}}
								>
									We found below accounts linked to your mobile{' '}
									<span
										style={{
											color: '#1168B4',
											fontWeight: 600,
											marginLeft: '2px',
										}}
									>
										{this.props?.match?.params?.number}
									</span>
								</p>
								{this.state.isSkip ? (
									<p
										className="fs-14 fw-500  text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										You have already linked your accounts. Click proceed to
										continue further.
									</p>
								) : (
									<p
										className="fs-14 fw-500  text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										We found below accounts linked to your mobile. Proceed to
										link. <br />
										OTP will be sent by institution for verification
									</p>
								)}
							</div>
							{this.state.discoveredAccounts.map((el, i) => {
								if (
									!isDiscoveredAccountLinked(el, this.state.linkedAccountsFull)
								) {
									return (
										<div className="col-12 mb-3" key={i}>
											<div className="row p-2 row-border">
												<div className="col-lg-2 col-3">
													<img
														src={this.state.selectedBank?.productIconUri}
														className="mt-2"
														style={{
															width: '45px',
															height: 'auto',
															objectFit: 'contain',
														}}
														alt="bank"
													/>
													<h1
														className="fs-10 text-center dark-grey"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{this.state.selectedBank?.productName}
													</h1>
												</div>
												<div
													className="col-lg-8 col-7"
													style={{ alignSelf: 'center' }}
												>
													<h1
														className="fs-16 fw-600 text-start text-dark"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.maskedAccNumber}
													</h1>
													<h1
														className="fs-14 fw-bold text-start"
														style={{
															color: '#2D9CDB',
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.accType}
													</h1>
												</div>
												<div
													className="col-lg-2 col-2"
													style={{ alignSelf: 'center' }}
												>
													<div className="form-check form-check-inline float-end">
														<span
															style={{ cursor: 'pointer' }}
															className={`form-check-input ${
																this.state.selectedAccounts.includes(el)
																	? 'selected-circle border-0'
																	: 'border-circle'
															} `}
															onClick={() => {
																this.selectUnselectAcc(el);
															}}
														></span>
													</div>
												</div>
											</div>
										</div>
									);
								} else {
									return;
								}
							})}
							{this.state.discoveredAccounts.map((el, i) => {
								if (
									isDiscoveredAccountLinked(el, this.state.linkedAccountsFull)
								) {
									return (
										<div className="col-12 mb-3" key={i}>
											<div className="row p-2 row-border">
												<div className="col-lg-2 col-3">
													<img
														src={this.state.selectedBank?.productIconUri}
														className="mt-2"
														style={{
															width: '45px',
															height: 'auto',
															objectFit: 'contain',
														}}
														alt="bank"
													/>
													<h1
														className="fs-10 text-center dark-grey"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{this.state.selectedBank?.productName}
													</h1>
												</div>
												<div
													className="col-lg-8 col-7"
													style={{ alignSelf: 'center' }}
												>
													<h1
														className="fs-16 fw-600 text-start text-dark"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.maskedAccNumber}
													</h1>
													<h1
														className="fs-14 fw-bold text-start"
														style={{
															color: '#2D9CDB',
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.accType}
													</h1>
												</div>
												<div
													className="col-lg-2 col-2"
													style={{ alignSelf: 'center' }}
												>
													<div className="form-check form-check-inline float-end">
														<span
															style={{
																fontFamily:
																	this.props.customDetails?.fontFamily,
															}}
														>
															LINKED
														</span>
														{/* {
                                                                    this.state.linkedAccounts.includes(el?.accRefNumber) ?
                                                                        <span>LINKED</span>
                                                                        :
                                                                        <span className={`form-check-input ${this.state.selectedAccounts.includes(el) ? 'selected-circle border-0' : 'border-circle'} `} onClick={() => { this.selectUnselectAcc(el) }}></span>
                                                                } */}
													</div>
												</div>
											</div>
										</div>
									);
								} else {
									return;
								}
							})}
							{this.state.isProceed ? (
								<div className="col-lg-6 offset-lg-3 col-12 pt-4 d-flex justify-content-center">
									{this.state.isSkip ? (
										<button
											type="button"
											className="btn float-end px-5 fs-16 fw-600 text-white"
											onClick={this.directProceed}
											style={{
												height: this.props.customDetails?.btnHeight,
												width: this.props.customDetails?.btnWidth,
												borderRadius: this.props.customDetails?.borderRadius,
												backgroundColor: this.props.customDetails?.primaryColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											PROCEED
										</button>
									) : (
										<button
											disabled={
												!this.state.selectedAccounts?.length ||
												this.state.isDisable
											}
											type="button"
											className="btn float-end px-5 fs-16 fw-600 w-100 text-white"
											onClick={this.accountLinking}
											style={{
												height: this.props.customDetails?.btnHeight,
												width: this.props.customDetails?.btnWidth,
												borderRadius: this.props.customDetails?.borderRadius,
												backgroundColor: this.props.customDetails?.primaryColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											{this.state.isDisable ? (
												<div
													className="snippet"
													style={{ display: 'flex', justifyContent: 'center' }}
													data-title=".dot-pulse"
												>
													<div className="stage">
														<div className="dot-pulse-btn"></div>
													</div>
												</div>
											) : (
												'PROCEED'
											)}
										</button>
									)}
								</div>
							) : null}
						</div>
						{global.isAddAccount ? (
							<button
								type="button"
								style={{ fontFamily: this.props.customDetails?.fontFamily }}
								className="btn btn-lg fs-14 w-100 fw-600 bleu-text-color my-3"
								onClick={() =>
									this.props.history.push(
										`/home/?number=${
											this.props.match.params?.number
										}&isAddAccount=${true}`
									)
								}
							>
								BACK
							</button>
						) : null}
					</div>
				)}
				<Sheet
					snapPoints={[400]}
					disableDrag={true}
					isOpen={this.state.isOpenSheet}
					onClose={() => this.setState({ isOpenSheet: false })}
				>
					<Sheet.Container>
						<Sheet.Content>
							<div className="row px-4">
								<div className="col-12 pt-4">
									<p
										className="fs-16 fw-600 text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										{this.state.selectedBank?.productName} has sent OTP for
										verification
									</p>
									<p
										className="fs-16 fw-600 text-start mt-4"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										Enter OTP as received on{' '}
										<span
											style={{
												color: this.props.customDetails?.subHeadingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											{this.props.match.params.number}
										</span>
									</p>
								</div>
								<div className="col-lg-4 col-md-6 col-12">
									<input
										type="text"
										pattern="^$|^[A-Z0-9]+$"
										title="Capital letters and numbers only"
										aria-label="OTP"
										className="form-control mb-2"
										value={this.state.OTP}
										onChange={this.handleOTPChange}
										style={{
											height: '50px',
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									></input>
									<div className="d-flex justify-content-between align-items-center">
										<button
											className="fs-14 fw-600 link-btn"
											disabled={!this.state.isResend}
											style={{
												color: this.state.isResend
													? this.props.customDetails?.subHeadingColor
													: 'grey',
											}}
											onClick={this.accountLinking}
										>
											Resend OTP
										</button>
										{this.state.time !== 60 ? (
											<p
												className="fs-14 fw-600 text-success my-auto"
												style={{
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												in {this.state.time} secs
											</p>
										) : null}
									</div>
								</div>
								{window.navigator.appVersion.indexOf('Mac') !== -1 ? (
									<div className="col-12 mt-3">
										<p
											className="text-lg-start text-center"
											style={{ margin: 0 }}
										>
											Please DO NOT switch app while reading the OTP
										</p>
									</div>
								) : null}

								<div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center mb-4">
									<button
										type="button"
										disabled={
											!(
												this.state.OTP?.length === 6 ||
												this.state.OTP?.length === 8
											) || this.state.isDisable
										}
										onClick={this.accountConfirmLinking}
										className="btn float-end px-5 fs-16 fw-600 text-white mt-3"
										style={{
											height: this.props.customDetails?.btnHeight,
											width: this.props.customDetails?.btnWidth,
											borderRadius: this.props.customDetails?.borderRadius,
											backgroundColor: this.props.customDetails?.primaryColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										{this.state.isVerifySpinner ? (
											<div
												className="snippet"
												style={{ display: 'flex', justifyContent: 'center' }}
												data-title=".dot-pulse"
											>
												<div className="stage">
													<div className="dot-pulse-btn"></div>
												</div>
											</div>
										) : (
											'VERIFY'
										)}
									</button>
								</div>
							</div>
						</Sheet.Content>
					</Sheet.Container>

					<Sheet.Backdrop />
				</Sheet>
			</div>
		);
	}
}
